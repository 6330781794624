// Source:
// Countries with long/lat => https://developers.google.com/public-data/docs/canonical/countries_csv
// Countries images => https://github.com/djaiss/mapsicon

const countryCodesWithImage = [
  "ason",
  "aund",
  "buer",
  "caon",
  "ceay",
  "cego",
  "chry",
  "chty",
  "clha",
  "duty",
  "fath",
  "gine",
  "gore",
  "grey",
  "haty",
  "hags",
  "haki",
  "houa",
  "huui",
  "inty",
  "kara",
  "kaik",
  "kast",
  "kaau",
  "loty",
  "maie",
  "matu",
  "magh",
  "maon",
  "mako",
  "naty",
  "nety",
  "neth",
  "opki",
  "otga",
  "paty",
  "poty",
  "ques",
  "raei",
  "roua",
  "ruhu",
  "seyn",
  "soki",
  "soto",
  "sopa",
  "sond",
  "strd",
  "taua",
  "taan",
  "tapo",
  "taty",
  "thel",
  "tiru",
  "upty",
  "wato",
  "wari",
  "wate",
  "wapa",
  "waoa",
  "waki",
  "wamo",
  "wely",
  "wety",
  "wend",
  "whne",
  "whui",
  "whei",
];

export interface Country {
  code: string;
  latitude: number;
  longitude: number;
  name: string;
}

export const countries: Country[] = [
  {
    code: "ason",
    latitude: -43.6498991669443,
    longitude: 171.401133972337,
    name: "Ashburton District",
  },
  {
    code: "ason",
    latitude: -43.649899,
    longitude: 171.401134,
    name: "Ashburton District",
  },
  {
    code: "aund",
    latitude: -36.707733,
    longitude: 174.731454,
    name: "Auckland",
  },
  {
    code: "buer",
    latitude: -41.768453,
    longitude: 172.031378,
    name: "Buller District",
  },
  {
    code: "caon",
    latitude: -41.065634,
    longitude: 175.620363,
    name: "Carterton District",
  },
  {
    code: "ceay",
    latitude: -40.024344,
    longitude: 176.520708,
    name: "Central Hawke's Bay District",
  },
  {
    code: "cego",
    latitude: -45.155218,
    longitude: 169.592928,
    name: "Central Otago District",
  },
  {
    code: "chry",
    latitude: -43.934966,
    longitude: -176.525245,
    name: "Chatham Islands Territory",
  },
  {
    code: "chty",
    latitude: -43.681114,
    longitude: 172.759662,
    name: "Christchurch City",
  },
  {
    code: "clha",
    latitude: -46.087324,
    longitude: 169.547036,
    name: "Clutha District",
  },
  {
    code: "duty",
    latitude: -45.671735,
    longitude: 170.251668,
    name: "Dunedin City",
  },
  {
    code: "fath",
    latitude: -35.195923,
    longitude: 173.56456,
    name: "Far North District",
  },
  {
    code: "gine",
    latitude: -38.281417,
    longitude: 177.921133,
    name: "Gisborne District",
  },
  {
    code: "gore",
    latitude: -46.07135,
    longitude: 168.962498,
    name: "Gore District",
  },
  {
    code: "grey",
    latitude: -42.492135,
    longitude: 171.580962,
    name: "Grey District",
  },
  {
    code: "haty",
    latitude: -37.772707,
    longitude: 175.267704,
    name: "Hamilton City",
  },
  {
    code: "hags",
    latitude: -39.417331,
    longitude: 176.627081,
    name: "Hastings District",
  },
  {
    code: "haki",
    latitude: -37.330975,
    longitude: 175.607917,
    name: "Hauraki District",
  },
  {
    code: "houa",
    latitude: -40.579569,
    longitude: 175.355294,
    name: "Horowhenua District",
  },
  {
    code: "huui",
    latitude: -42.697116,
    longitude: 172.731409,
    name: "Hurunui District",
  },
  {
    code: "inty",
    latitude: -46.469393,
    longitude: 168.358104,
    name: "Invercargill City",
  },
  {
    code: "kaik",
    latitude: -42.198687,
    longitude: 173.643585,
    name: "Kaikoura District",
  },
  {
    code: "kara",
    latitude: -35.991701,
    longitude: 174.023534,
    name: "Kaipara District",
  },
  {
    code: "kast",
    latitude: -40.861816,
    longitude: 175.170183,
    name: "Kapiti Coast District",
  },
  {
    code: "kaau",
    latitude: -38.090204,
    longitude: 176.690438,
    name: "Kawerau District",
  },
  {
    code: "loty",
    latitude: -41.278794,
    longitude: 174.956817,
    name: "Lower Hutt City",
  },
  {
    code: "maie",
    latitude: -43.964972,
    longitude: 170.447009,
    name: "Mackenzie District",
  },
  {
    code: "matu",
    latitude: -40.114235,
    longitude: 175.695887,
    name: "Manawatu District",
  },
  {
    code: "magh",
    latitude: -41.695105,
    longitude: 173.530952,
    name: "Marlborough District",
  },
  {
    code: "maon",
    latitude: -40.897163,
    longitude: 175.883917,
    name: "Masterton District",
  },
  {
    code: "mako",
    latitude: -37.668694,
    longitude: 175.662535,
    name: "Matamata-Piako District",
  },
  {
    code: "naty",
    latitude: -39.496859,
    longitude: 176.87017,
    name: "Napier City",
  },
  {
    code: "nety",
    latitude: -41.246666,
    longitude: 173.391528,
    name: "Nelson City",
  },
  {
    code: "neth",
    latitude: -39.050023,
    longitude: 174.375252,
    name: "New Plymouth District",
  },
  {
    code: "opki",
    latitude: -38.012885,
    longitude: 177.578589,
    name: "Ōpōtiki District",
  },
  {
    code: "otga",
    latitude: -38.199436,
    longitude: 175.292202,
    name: "Ōtorohanga District",
  },
  {
    code: "paty",
    latitude: -40.386233,
    longitude: 175.642694,
    name: "Palmerston North City",
  },
  {
    code: "poty",
    latitude: -41.092076,
    longitude: 174.904781,
    name: "Porirua City",
  },
  {
    code: "ques",
    latitude: -44.67501,
    longitude: 168.854264,
    name: "Queenstown-Lakes District",
  },
  {
    code: "raei",
    latitude: -39.688108,
    longitude: 175.782485,
    name: "Rangitikei District",
  },
  {
    code: "roua",
    latitude: -38.222752,
    longitude: 176.326109,
    name: "Rotorua District",
  },
  {
    code: "ruhu",
    latitude: -39.108623,
    longitude: 175.336336,
    name: "Ruapehu District",
  },
  {
    code: "seyn",
    latitude: -43.309539,
    longitude: 171.878682,
    name: "Selwyn District",
  },
  {
    code: "soki",
    latitude: -39.534296,
    longitude: 174.431853,
    name: "South Taranaki District",
  },
  {
    code: "soto",
    latitude: -38.16652,
    longitude: 175.868241,
    name: "South Waikato District",
  },
  {
    code: "sopa",
    latitude: -41.303645,
    longitude: 175.392473,
    name: "South Wairarapa District",
  },
  {
    code: "sond",
    latitude: -45.70464,
    longitude: 167.880506,
    name: "Southland District",
  },
  {
    code: "strd",
    latitude: -39.218,
    longitude: 174.621545,
    name: "Stratford District",
  },
  {
    code: "taua",
    latitude: -40.443721,
    longitude: 176.06287,
    name: "Tararua District",
  },
  {
    code: "taan",
    latitude: -41.449595,
    longitude: 172.648807,
    name: "Tasman District",
  },
  {
    code: "tapo",
    latitude: -38.813742,
    longitude: 176.047183,
    name: "Taupo District",
  },
  {
    code: "taty",
    latitude: -37.712452,
    longitude: 176.190964,
    name: "Tauranga City",
  },
  {
    code: "thel",
    latitude: -36.935267,
    longitude: 175.642499,
    name: "Thames-Coromandel District",
  },
  {
    code: "tiru",
    latitude: -44.029129,
    longitude: 171.086176,
    name: "Timaru District",
  },
  {
    code: "upty",
    latitude: -41.079538,
    longitude: 175.114122,
    name: "Upper Hutt City",
  },
  {
    code: "wato",
    latitude: -37.537723,
    longitude: 175.07288,
    name: "Waikato District",
  },
  {
    code: "wari",
    latitude: -43.248537,
    longitude: 172.312301,
    name: "Waimakariri District",
  },
  {
    code: "wate",
    latitude: -44.609173,
    longitude: 170.759243,
    name: "Waimate District",
  },
  {
    code: "wapa",
    latitude: -37.964788,
    longitude: 175.399439,
    name: "Waipa District",
  },
  {
    code: "waoa",
    latitude: -38.898504,
    longitude: 177.235615,
    name: "Wairoa District",
  },
  {
    code: "waki",
    latitude: -44.819267,
    longitude: 170.293677,
    name: "Waitaki District",
  },
  {
    code: "wamo",
    latitude: -38.455658,
    longitude: 175.008454,
    name: "Waitomo District",
  },
  {
    code: "wely",
    latitude: -41.256979,
    longitude: 174.75012,
    name: "Wellington City",
  },
  {
    code: "wety",
    latitude: -37.789342,
    longitude: 176.172879,
    name: "Western Bay of Plenty District",
  },
  {
    code: "wend",
    latitude: -43.552231,
    longitude: 169.908769,
    name: "Westland District",
  },
  {
    code: "whne",
    latitude: -38.313066,
    longitude: 176.855795,
    name: "Whakatane District",
  },
  {
    code: "whui",
    latitude: -39.707849,
    longitude: 175.134056,
    name: "Whanganui District",
  },
  {
    code: "whei",
    latitude: -35.715959,
    longitude: 174.250999,
    name: "Whangarei District",
  },
];

export const countriesWithImage = countries.filter((c) =>
  countryCodesWithImage.includes(c.code.toLowerCase())
);

// Source: https://fr.wikipedia.org/wiki/ISO_3166
const frenchCountryNames: Record<string, string> = {
  AF: "Afghanistan",
  AX: "Åland",
  AL: "Albanie",
  DZ: "Algérie",
  AS: "Samoa américaines",
  AD: "Andorre",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctique",
  AG: "Antigua-et-Barbuda",
  AR: "Argentine",
  AM: "Arménie",
  AW: "Aruba",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BS: "Bahamas",
  BH: "Bahreïn",
  BD: "Bangladesh",
  BB: "Barbade",
  BY: "Bélarus",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermudes",
  BT: "Bhoutan",
  BO: "Bolivie",
  BQ: "Bonaire, Saint-Eustache et Saba",
  BA: "Bosnie-Herzégovine",
  BW: "Botswana",
  BV: "Bouvet",
  BR: "Brésil",
  IO: "Territoire britannique de l'océan Indien (Diego Garcia)",
  BN: "Brunéi Darussalam",
  BG: "Bulgarie",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodge",
  CM: "Cameroun",
  CA: "Canada",
  KY: "Caïmans",
  CF: "République centrafricaine",
  TD: "Tchad",
  CL: "Chili",
  CN: "Chine",
  CX: "Christmas",
  CC: "Cocos",
  CO: "Colombie",
  KM: "Comores",
  CD: "République démocratique du Congo",
  CG: "Congo",
  CK: "Cook",
  CR: "Costa Rica",
  HR: "Croatie",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Chypre",
  CZ: "Tchéquie",
  DK: "Danemark",
  DJ: "Djibouti",
  DM: "Dominique",
  DO: "République dominicaine",
  EC: "Équateur",
  EG: "Égypte",
  SV: "Salvador",
  GQ: "Guinée équatoriale",
  ER: "Érythrée",
  EE: "Estonie",
  SZ: "Eswatini",
  ET: "Éthiopie",
  FK: "Îles Falkland (Malvinas)",
  FO: "Féroé",
  FJ: "Fidji",
  FI: "Finlande",
  FR: "France",
  GF: "Guyane française",
  PF: "Polynésie française",
  TF: "Terres australes françaises",
  GA: "Gabon",
  GM: "Gambie",
  GE: "Géorgie",
  DE: "Allemagne",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grèce",
  GL: "Groenland",
  GD: "Grenade",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernesey",
  GN: "Guinée",
  GW: "Guinée-Bissau",
  GY: "Guyana",
  HT: "Haïti",
  HM: "Heard-et-Îles MacDonald",
  VA: "Saint-Siège",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hongrie",
  IS: "Islande",
  IN: "Inde",
  ID: "Indonésie",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Irlande",
  IM: "Île de Man",
  IL: "Israël",
  IT: "Italie",
  CI: "Côte d'Ivoire",
  JM: "Jamaïque",
  JP: "Japon",
  JE: "Jersey",
  JO: "Jordanie",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Corée du Nord",
  KR: "Corée du Sud",
  KW: "Koweït",
  KG: "Kirghizistan",
  LA: "Lao",
  LV: "Lettonie",
  LB: "Liban",
  LS: "Lesotho",
  LR: "Libéria",
  LY: "Libye",
  LI: "Liechtenstein",
  LT: "Lituanie",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaisie",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malte",
  MH: "Marshall",
  MQ: "Martinique",
  MR: "Mauritanie",
  MU: "Maurice",
  YT: "Mayotte",
  MX: "Mexique",
  FM: "Micronésie",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolie",
  ME: "Monténégro",
  MS: "Montserrat",
  MA: "Maroc",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibie",
  NR: "Nauru",
  NP: "Népal",
  NL: "Pays-Bas",
  NC: "Nouvelle-Calédonie",
  NZ: "Nouvelle-Zélande",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigéria",
  NU: "Niue",
  NF: "Norfolk",
  MK: "Macédoine du Nord",
  MP: "Mariannes du Nord",
  NO: "Norvège",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palaos",
  PS: "Palestine",
  PA: "Panama",
  PG: "Papouasie-Nouvelle-Guinée",
  PY: "Paraguay",
  PE: "Pérou",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Pologne",
  PT: "Portugal",
  PR: "Porto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Roumanie",
  RU: "Russie",
  RW: "Rwanda",
  BL: "Saint-Barthélemy",
  SH: "Sainte-Hélène, Ascension et Tristan da Cunha",
  KN: "Saint-Kitts-et-Nevis",
  LC: "Sainte-Lucie",
  MF: "Saint-Martin",
  PM: "Saint-Pierre-et-Miquelon",
  VC: "Saint-Vincent-et-les Grenadines",
  WS: "Samoa",
  SM: "Saint-Marin",
  ST: "Sao Tomé-et-Principe",
  SA: "Arabie saoudite",
  SN: "Sénégal",
  RS: "Serbie",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapour",
  SX: "Saint-Martin",
  SK: "Slovaquie",
  SI: "Slovénie",
  SB: "Salomon",
  SO: "Somalie",
  ZA: "Afrique du Sud",
  GS: "Géorgie du Sud-et-les Îles Sandwich du Sud",
  SS: "Soudan du Sud",
  ES: "Espagne",
  LK: "Sri Lanka",
  SD: "Soudan",
  SR: "Suriname",
  SJ: "Svalbard et l'Île Jan Mayen",
  SE: "Suède",
  CH: "Suisse",
  SY: "Syrie",
  TW: "Taïwan",
  TJ: "Tadjikistan",
  TZ: "Tanzanie",
  TH: "Thaïlande",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinité-et-Tobago",
  TN: "Tunisie",
  TR: "Turquie",
  TM: "Turkménistan",
  TC: "Turks-et-Caïcos",
  TV: "Tuvalu",
  UG: "Ouganda",
  UA: "Ukraine",
  AE: "Émirats arabes unis",
  GB: "Royaume-Uni",
  UM: "Îles mineures éloignées des États-Unis",
  US: "États-Unis d'Amérique",
  UY: "Uruguay",
  UZ: "Ouzbékistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Vierges britanniques",
  VI: "Vierges des États-Unis",
  WF: "Wallis-et-Futuna",
  EH: "Sahara occidental",
  YE: "Yémen",
  ZM: "Zambie",
  ZW: "Zimbabwe",
  AN: "Antilles néerlandaises",
  GZ: "Bande de Gaza",
  XK: "Kosovo",
};

export function getCountryName(language: string, country: Country) {
  if (language === "fr") {
    return frenchCountryNames[country.code];
  }
  return country.name;
}

export function sanitizeCountryName(countryName: string): string {
  return countryName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}
